import * as React from "react"
// import { Link } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
// React Bootstrap
import { Container, Row, Col, Carousel } from "react-bootstrap"

import { StaticImage } from "gatsby-plugin-image"

import Consultation from "../components/free-consultation"

const SecondPage = () => (
  <Layout>
    <Seo title="Additions" />
    <Container>


    <h1 className="text-center mb-4">Additions & Remodels</h1>
    <Row>
      <Col lg={7}>
      <Carousel interval={100000} variant="dark">
  <Carousel.Item>
    <StaticImage 
      src="../images/projects-complete-house-remodel-woodacre/01-wasowext_orig.jpg"
      alt="Sketch of House"
      height="700"
      className="max-slide-show-width"
      formats={["auto", "webp", "avif"]}
    />
  </Carousel.Item>
  <Carousel.Item>
    <StaticImage 
      src="../images/projects-complete-house-remodel-woodacre/02-wasowlr_orig.jpg"
      alt="Sketch of House"
      height="700"
      className="max-slide-show-width"
      formats={["auto", "webp", "avif"]}
    />

    <Carousel.Caption>
      <p>A new stair to the second floor was designed.</p>
    </Carousel.Caption>
  </Carousel.Item>
  <Carousel.Item>
    <StaticImage 
      src="../images/projects-complete-house-remodel-woodacre/03-wasowupperrm_orig.jpg"
      alt="Sketch of House"
      height="700"
      className="max-slide-show-width"
      formats={["auto", "webp", "avif"]}
    />

    <Carousel.Caption>
      <p>A separate one-story addition was refurbished to serve as a home office and guest bedroom.</p>
    </Carousel.Caption>
  </Carousel.Item>
  <Carousel.Item>
    <StaticImage 
      src="../images/projects-complete-house-remodel-woodacre/04-wasowhall_orig.png"
      alt="Sketch of House"
      height="700"
      className="max-slide-show-width"
      formats={["auto", "webp", "avif"]}
    />

    <Carousel.Caption>
      <p>The site has limited sun at the bottom of San Geronimo Valley, so many skylights were added to give this house its airy feeling.</p>
    </Carousel.Caption>
  </Carousel.Item>
</Carousel>
      </Col>
      <Col lg={5}>
        <h2>Complete House Remodel in Woodacre, CA​</h2>
        <p>
        This very small house (1123 sq.ft.) was built in 1923. Extensive remodeling included a new kitchen, two new baths, a laundry, and restoring two bedrooms plus a loft. A new stair to the second floor was designed. A separate one-story addition was refurbished to serve as a
home office and guest bedroom.
        </p>
        <p>
        The site has limited sun at the bottom of San Geronimo Valley, so many skylights were added to give this house its airy feeling.
        </p>
      </Col>
    </Row>

    <hr />

    <Row>
      <Col lg={7}>
      <Carousel interval={100000}>
  <Carousel.Item>
    <StaticImage 
      src="../images/garage-mill-valley/01-duckextuptodeck_orig.jpg"
      alt="Deck Exterior Up To deck"
      height="700"
      className="max-slide-show-width"
      formats={["auto", "webp", "avif"]}
    />
  </Carousel.Item>
  <Carousel.Item>
    <StaticImage 
      src="../images/garage-mill-valley/02-duckextgarage_orig.jpg"
      alt="Exterior Garage"
      placeholder="blurred"
      height="700"
      className="max-slide-show-width"
      formats={["auto", "webp", "avif"]}
    />
  </Carousel.Item>
  <Carousel.Item>
    <StaticImage 
      src="../images/garage-mill-valley/03-duckextside_orig.jpg"
      alt="Exterior Deck"
      height="700"
      className="max-slide-show-width"
      formats={["auto", "webp", "avif"]}
    />
  </Carousel.Item>
  <Carousel.Item>
    <StaticImage 
      src="../images/garage-mill-valley/04-duckextofficewindow_orig.png"
      alt="Office Window"
      height="700"
      className="max-slide-show-width"
      formats={["auto", "webp", "avif"]}
    />

  </Carousel.Item>
</Carousel>
      </Col>
      <Col lg={5}>
        <h2>New Garage With Studio in Mill Valley, CA</h2>
        <p>
        After their garage was crushed by a falling tree, the owners opted to rebuild a slightly wider garage with an office and studio apartment below. The new exterior uses fire-treated plywood so it can match the adjacent main house while complying with Wildland-Urban Interface (WUI) requirements.
        </p>
      </Col>
    </Row>

    <hr />

    <Row>
      <Col lg={7}>
      <Carousel interval={100000} variant="dark">
  <Carousel.Item>
    <StaticImage 
      src="../images/second-floor-tiburon/01-darufrontoverall_orig.jpg"
      alt="Outside view of House"
      height="700"
      className="max-slide-show-width"
      formats={["auto", "webp", "avif"]}
    />
  </Carousel.Item>
  <Carousel.Item>
    <StaticImage 
      src="../images/second-floor-tiburon/02-daruentry_1_orig.png"
      alt="Closeup of House Entryway"
      placeholder="blurred"
      height="700"
      className="max-slide-show-width"
      formats={["auto", "webp", "avif"]}
    />
  </Carousel.Item>
</Carousel>
      </Col>
      <Col lg={5}>
        <h2>Second Floor Addition in Tiburon, CA</h2>
        <p>
        The owners wanted a new Master Bedroom suite and a second Bedroom with bath at a new addition above the garage, so their house would accommodate their grown children and grandchildren. To provide a new stair, the former dining room was converted to a home office and the new dining room opens space all the way from a restyled front entry to the back yard. The design was approved by Tiburon Design Review in only one short hearing.
        </p>
      </Col>
    </Row>

    <hr />

    <Row>
      <Col lg={7}>
      <Carousel interval={100000}>
  <Carousel.Item>
    <StaticImage 
      src="../images/house-model-santa-barbara/01-front-exterior_orig.jpg"
      alt="Front of house"
      height="700"
      className="max-slide-show-width"
      formats={["auto", "webp", "avif"]}
    />
  </Carousel.Item>
  <Carousel.Item>
    <StaticImage 
      src="../images/house-model-santa-barbara/02-bimage-153334064_orig.jpg"
      alt="Backyard of house"
      placeholder="blurred"
      height="700"
      className="max-slide-show-width"
      formats={["auto", "webp", "avif"]}
    />
  </Carousel.Item>
  <Carousel.Item>
    <StaticImage 
      src="../images/house-model-santa-barbara/03-dining-wkitch-beyond_orig.jpg"
      alt="Remodeled dining area"
      height="700"
      className="max-slide-show-width"
      formats={["auto", "webp", "avif"]}
    />
  </Carousel.Item>
  <Carousel.Item>
    <StaticImage 
      src="../images/house-model-santa-barbara/04-living-room_orig.jpg"
      alt="Remodeled living room"
      height="700"
      className="max-slide-show-width"
      formats={["auto", "webp", "avif"]}
    />

  </Carousel.Item>
  <Carousel.Item>
    <StaticImage 
      src="../images/house-model-santa-barbara/05-master-bath_orig.jpg"
      alt="Remodeled Bathroom"
      height="700"
      className="max-slide-show-width"
      formats={["auto", "webp", "avif"]}
    />

  </Carousel.Item>
</Carousel>
      </Col>
      <Col lg={5}>
        <h2>Vacation Rental House Remodel in Santa Barbara, CA</h2>
        <p>
        This historic house is in a commercial district near State Street and the beach – one of only a few areas in the City allowed for short-term rentals.  Work included restoring the house exterior to something like its original condition from being a series of chopped-up small shops.  A new kitchen opens to the adjacent dining room.  A new Master Bedroom was created by merging two rooms separated by a bearing wall.  New windows emulate the leaded top panel of historic windows.
        </p>
      </Col>
    </Row>

    <hr />

    <Row>
      <Col lg={7}>
      <Carousel interval={100000}  variant="dark">
  <Carousel.Item>
    <StaticImage 
      src="../images/joshua-tree-modernization/01-luna-hot-tub_orig.jpg"
      alt="Hot Tub in Front of House"
      height="700"
      className="max-slide-show-width"
      formats={["auto", "webp", "avif"]}
    />
  </Carousel.Item>
  <Carousel.Item>
    <StaticImage 
      src="../images/joshua-tree-modernization/02-aurora-bedroom_orig.jpg"
      alt="Front Master Bedroom with morning light"
      placeholder="blurred"
      height="700"
      className="max-slide-show-width"
      formats={["auto", "webp", "avif"]}
    />
  </Carousel.Item>
  <Carousel.Item>
    <StaticImage 
      src="../images/joshua-tree-modernization/03-luna-living-room_orig.jpg"
      alt="Living Room with Dark Furniture"
      height="700"
      className="max-slide-show-width"
      formats={["auto", "webp", "avif"]}
    />
  </Carousel.Item>
  <Carousel.Item>
    <StaticImage 
      src="../images/joshua-tree-modernization/04-aurora-guest-bedroom_orig.jpg"
      alt="Guest Room with Red Colors"
      height="700"
      className="max-slide-show-width"
      formats={["auto", "webp", "avif"]}
    />

  </Carousel.Item>
  <Carousel.Item>
    <StaticImage 
      src="../images/joshua-tree-modernization/05-aurora-basement_orig.jpg"
      alt="Entertainment Room"
      height="700"
      className="max-slide-show-width"
      formats={["auto", "webp", "avif"]}
    />

  </Carousel.Item>
  <Carousel.Item>
    <StaticImage 
      src="../images/joshua-tree-modernization/06-aurora-living-room_orig.png"
      alt="Additional Entertainment Room"
      height="700"
      className="max-slide-show-width"
      formats={["auto", "webp", "avif"]}
    />

  </Carousel.Item>
</Carousel>
      </Col>
      <Col lg={5}>
        <h2>Joshua Tree, CA Home Modernization</h2>
        <p>
        With interior designer Kristi Allain, converted two run-down houses into top vacation rental destinations. Work included adding new heating and air conditioning, replacing cracked siding with a new exterior, rebuilding a rotting second-floor deck, remodeled kitchens and bathrooms, converting a dark basement into a game room with wet bar, and converting the garage to a yoga studio. All work meets stringent San Bernardino Wildland-Urban
Interface (WUI) requirements.
        </p>
      </Col>
    </Row>

    <hr />
    </Container>
    
    <div className="mt-3">
      <Consultation  />
    </div>
    
  </Layout>
)

export default SecondPage
